<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <v-card class="mx-auto" elevation="8" :loading="showLoading">
          <v-list-item three-line>
            <v-list-item-content>
              <v-list-item-title class="headline mb-1">
                出欠確認
              </v-list-item-title>

              <v-list-item-subtitle>
                授業ごとの出欠を確認することができます。<br />
                <span v-if="canResearch"
                  >「授業満足度調査」は<router-link to="/timetable"
                    >時間割</router-link
                  >のページから行ってください。</span
                >
              </v-list-item-subtitle>
              <v-btn x-small rounded @click="toggleShow">
                <v-icon>{{
                  show ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon>
                詳細説明を{{ show ? "閉じる" : "開く" }}
              </v-btn>
              <v-expand-transition>
                <div v-show="show">
                  <v-divider></v-divider>
                  <v-card-text>
                    <ol>
                      <li>
                        学生は登録済み授業を選択します。講師は担当授業を選択します。
                      </li>
                      <li>
                        授業を選択された状態になると、出欠確認が表示されます。
                      </li>
                      <li>
                        学生は自分の授業の出欠記録が確認できます。講師は履修生全員の出欠を入力・確認できます。
                      </li>
                    </ol>
                  </v-card-text>
                </div>
              </v-expand-transition>
            </v-list-item-content>
          </v-list-item>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
export default {
  name: "Information",
  data: () => ({}),
  computed: {
    show() {
      return this.$store.state.detailShow;
    },
    canResearch() {
      return this.$store.state.canResearch;
    },
    showLoading() {
      return this.$store.state.isLoadingAttendance;
    }
  },
  methods: {
    toggleShow() {
      this.$store.commit("TOGGLE_DETAIL_SHOW");
    }
  }
};
</script>
<style scoped>
.info-messages li {
  line-height: 1.5rem;
}
.additional-message {
  padding: 1rem 0 0 0;
}
</style>
