<template>
  <v-row>
    <v-col cols="12">
      <v-select
        :value="period"
        @input="sendPeriod"
        :items="periods"
        item-text="label"
        item-value="id"
        label="実施時限"
        outlined
      ></v-select>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "PeriodSelect",
  props: ["period"],
  components: {},
  data: () => ({
    periods: [
      { id: "1", label: "1限" },
      { id: "2", label: "2限" },
      { id: "3", label: "3限" },
      { id: "4", label: "4限" },
      { id: "5", label: "5限" },
      { id: "6", label: "その他" }
    ]
  }),
  computed: {},
  methods: {
    sendPeriod(p) {
      // this.period = p;
      this.$emit("period", p);
    }
  },
  created() {
    this.$emit("period", this.period);
  }
};
</script>
