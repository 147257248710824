<template>
  <v-container v-if="getClassroom(courseCode)">
    <v-row>
      <v-col cols="12" md="12">
        <h1>出欠詳細を表示中</h1>
      </v-col>
      <v-col
        cols="12"
        xm="12"
        sm="6"
        md="6"
        v-for="c in getClassrooms(courseCode)"
        :key="c.courseCode"
        ><Classroom
          :numberOf="1"
          :classrooms="[c]"
          view="register"
          :inActive="false"
        />
      </v-col>
      <v-col cols="12" xm="12" sm="6" md="6">
        <v-card class="mx-auto" elevation="8" :height="cardHeight()">
          <v-list-item three-line class="my-0 py-0">
            <v-list-item-content class="md-0">
              <v-list-item-title class="headline mb-1">
                <p v-if="attendanceDateObj">
                  {{ attendanceDateObj.date.getMonth() + 1 }}月{{
                    attendanceDateObj.date.getDate()
                  }}日（{{ attendanceDateObj.period }}限）
                  {{ getAdditionJa(attendanceDateObj) }}
                </p>
              </v-list-item-title>
              <v-list-item-subtitle
                v-if="attendanceDateObj && attendanceDateObj.memo"
              >
                メモ(Memo): {{ attendanceDateObj.memo }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                各種操作(Additional operation)
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions class="ma-4 pa-2">
            <v-btn :to="`/attendance/${courseCode}`" color="primary"
              >戻る(Return)</v-btn
            >

            <v-dialog
              v-if="hasCoordinatorPermission && canEditDate"
              v-model="dialog"
              width="500"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn class="ma-2" color="accent" dark v-bind="attrs" v-on="on"
                  ><span v-if="true">授業日変更</span>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="headline grey lighten-2">
                  <span>授業日の変更</span>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="dialog = false">
                    閉じる
                  </v-btn>
                </v-card-title>
                <v-card-text v-if="attendanceDateObj">
                  <AddDateForm
                    :classroom="getClassroom(courseCode)"
                    @dialog="dialog = false"
                    mode="edit"
                    :defaultMemo="attendanceDateObj.memo"
                    :defaultDate="attendanceDateObj.date"
                    :defaultType="
                      attendanceDateObj.addition ? 'addition' : 'normal'
                    "
                    :defaultPeriod="attendanceDateObj.period"
                    :attendanceId="attendanceDateObj.attendanceId"
                  />
                </v-card-text>
                <v-divider></v-divider>
              </v-card>
            </v-dialog>
          </v-card-actions>

          <v-card-actions class="ma-4 pa-2">
            <v-switch
              :input-value="viewFixed"
              @change="switchFix"
              :disabled="!canFixChange"
              :label="`出欠確定`"
            ></v-switch>
            <v-spacer></v-spacer>
            <v-switch
              :input-value="viewCanceled"
              @change="switchCancel"
              :disabled="!canCancelChange"
              :label="`休講`"
            ></v-switch>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">
              氏名(Name)
            </th>
            <th class="text-center">
              English Name
            </th>
            <th class="text-center">
              学籍番号
            </th>
            <th class="text-center" v-for="status in statuses" :key="status.id">
              {{ status.label }}
            </th>
            <th class="text-center">
              メモ(Memo)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(student, i) in students" :key="`${student.uid}-${i}`">
            <th>
              <router-link :to="getStudentPath(student.uid)">{{
                student.nameOrEmail
              }}</router-link>
            </th>
            <th>{{ student.enName }}</th>
            <th>{{ getNumberByEmail(student.email) }}</th>
            <td
              class="text-center ma-1 pa-1"
              v-for="(status, j) in statuses"
              :key="`${status.id}-${j}`"
            >
              <StatusBtn
                :label="status.label"
                :enLabel="status.enLabel"
                :statusId="status.id"
                :student="student"
                :attendanceDateObj="attendanceDateObj"
                @check="doStatusCehck"
              />
            </td>
            <td>
              <MemoInput
                :courseCode="courseCode"
                :attendanceDateObj="attendanceDateObj"
                :student="student"
              />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>
<script>
import StatusBtn from "./StatusBtn";
import Classroom from "../Classroom";
import AddDateForm from "./AddDate";
import MemoInput from "./MemoInput";

export default {
  name: "TableDetail",
  props: ["courseCode", "attendanceDateObj"],
  components: {
    StatusBtn,
    Classroom,
    AddDateForm,
    MemoInput
  },
  data: () => ({
    dialog: false,
    statuses: [
      { id: "attended", label: "出席(A)", enLabel: "Attendance" },
      { id: "absence", label: "欠席(D)", enLabel: "Absence" },
      { id: "late", label: "遅刻(L)", enLabel: "Late" },
      { id: "left", label: "早退(E)", enLabel: "Early" }
    ],
    weekdays: [
      { id: 1, name: "月曜(Mo)" },
      { id: 2, name: "火曜(Tu)" },
      { id: 3, name: "水曜(We)" },
      { id: 4, name: "木曜(Th)" },
      { id: 5, name: "金曜(Fr)" },
      { id: 6, name: "土曜(Sa)" },
      { id: 0, name: "日曜(Su)" }
      //   { id: 0, name: "その他" }
    ]
  }),
  computed: {
    students() {
      return this.$store.state.studentsAttendance
        .filter(s => {
          return s.active;
        })
        .slice()
        .sort((a, b) => {
          return a.sortedName < b.sortedName ? -1 : 1;
        });
    },
    viewFixed() {
      return !!this.attendanceDateObj?.fixed;
    },
    viewCanceled() {
      return !!this.attendanceDateObj?.canceled;
    },
    canFixChange() {
      return true; // todo
    },
    canCancelChange() {
      if (this.attendanceDateObj && this.attendanceDateObj.attendanceStatus) {
        const hasStatuses = this.attendanceDateObj.attendanceStatus.filter(
          s => {
            return s.status !== null;
          }
        );
        if (hasStatuses.length > 0) {
          return false;
        } else {
          if (this.viewCanceled) {
            return true;
          } else if (this.hasCoordinatorPermission) {
            return true;
          } else {
            return true;
          }
        }
      } else {
        return false;
      }
    },
    canEditDate() {
      if (this.viewFixed || this.viewCanceled) {
        return false;
      } else if (!this.canCancelChange) {
        return false;
      } else {
        return true;
      }
    },
    hasCoordinatorPermission() {
      const userType = this.$store.state.userType;
      if (userType === "coordinator") {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    cardHeight() {
      return this.$store.state.cardHeight;
    },
    getAdditionJa(day) {
      return day.addition ? "補講(A)" : "通常(N)";
    },
    getStudentPath(studentId) {
      return `/attendance/${this.courseCode}/student/${studentId}`;
    },
    getWeekdayColor(weekdayId) {
      if (weekdayId == 1) {
        return "#779438";
      } else if (weekdayId == 2) {
        return "#007d7a";
      } else if (weekdayId == 3) {
        return "#055a8e";
      } else if (weekdayId == 4) {
        return "#772f6d";
      } else if (weekdayId == 5) {
        return "#a7374a";
      } else {
        return "#000";
      }
    },
    getClassrooms(courseCode) {
      const results = this.$store.state.classrooms.filter(c => {
        return c.courseCode == courseCode || c.parentCourseCode == courseCode;
      });
      return results;
    },
    getClassroom(courseCode) {
      const result = this.$store.state.classrooms.filter(
        c => c.courseCode == courseCode
      );
      if (result.length > 0) {
        return result[0];
      } else {
        return null;
      }
    },
    getWeekdayName(weekdayId) {
      const week = this.weekdays.filter(w => {
        return w.id == weekdayId;
      });
      if (week.length > 0) {
        return week[0].name;
      }
      return "";
    },
    doStatusCehck(statusId, student, statused) {
      const courseCode = this.courseCode;
      const attendanceId = this.attendanceDateObj.attendanceId;
      const studentId = student.uid;
      // const fixed = statused.fixed
      // const sent = statused.sent
      const status = statused?.status;
      const late = statused?.late;
      const left = statused?.left;
      this.$store.dispatch("doAttendanceStatusModify", {
        courseCode,
        attendanceId,
        studentId,
        statusId,
        status,
        late,
        left
      });
    },
    switchFix(v) {
      this.$store.dispatch("doFixAttendanceDate", {
        courseCode: this.courseCode,
        attendanceDate: this.attendanceDateObj,
        fixed: v ? true : false
      });
    },
    switchCancel(v) {
      this.$store.dispatch("doCancelAttendanceDate", {
        courseCode: this.courseCode,
        attendanceDate: this.attendanceDateObj,
        canceled: v ? true : false
      });
    },
    getNumberByEmail(email) {
      return email.split("@")[0];
    }
  }
};
</script>
<style scoped>
.weekdayText {
  color: #fff;
}
</style>
