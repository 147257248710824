<template>
  <v-container class="pa-1">
    <span v-if="canMemo">
      <span v-if="edit">
        <v-text-field
          :value="getMemo()"
          @input="setMemo"
          append-outer-icon="mdi-send"
          @click:append-outer="sendMemo"
        ></v-text-field>
      </span>
      <span v-if="!edit">
        {{ getMemo() }}
        <v-btn class="ma-0" icon @click="edit = true"
          ><v-icon x-small>mdi-pencil</v-icon></v-btn
        ></span
      >
    </span>
    <span v-else>{{ getMemo() }}</span>
  </v-container>
</template>
<script>
export default {
  name: "MemoInput",
  props: ["courseCode", "attendanceDateObj", "student"],
  data() {
    return {
      edit: false,
      memo: ""
    };
  },
  computed: {
    attendanceStatus() {
      const attendanceDateObj = this.attendanceDateObj;
      const student = this.student;
      if (!attendanceDateObj || !student) {
        return null;
      }
      const attendanceStatuses = attendanceDateObj.attendanceStatus.filter(
        s => {
          return s.studentId == student.uid;
        }
      );
      if (attendanceStatuses.length > 0) {
        const attendanceStatus = attendanceStatuses[0];
        return attendanceStatus;
      } else {
        return null;
      }
    },
    canMemo() {
      if (this.attendanceStatus) {
        const fixed = this.attendanceStatus.fixed;
        const sent = this.attendanceStatus.sent;
        if (fixed || sent) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  },
  methods: {
    getMemo() {
      if (this.attendanceStatus) {
        const comment = this.attendanceStatus.comment;
        return comment;
      } else {
        return null;
      }
    },
    setMemo(data) {
      this.memo = data;
    },
    sendMemo() {
      const courseCode = this.courseCode;
      const fixed = this.attendanceStatus.fixed;
      const sent = this.attendanceStatus.sent;
      const memo = this.memo;
      if (!(fixed || sent)) {
        const attendanceId = this.attendanceDateObj.attendanceId;
        const studentId = this.student.uid;
        this.$store.dispatch("doAttendanceStatusComment", {
          courseCode,
          attendanceId,
          studentId,
          memo
        });
      }
      this.edit = false;
    }
    // cancelEdit() {
    //   this.edit = false;
    // }
  }
};
</script>
<style scoped></style>
