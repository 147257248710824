<template>
  <v-container class="pa-1">
    <v-tooltip top>
      <template v-slot:activator="{ on }">
        <v-btn
          v-on="on"
          small
          class="ma-0"
          :color="color"
          @click="statusCheck"
          :disabled="isDisable"
        >
          {{ label }}
        </v-btn>
      </template>
      <span>{{ label }} ({{ enLabel }})</span>
    </v-tooltip>
  </v-container>
</template>
<script>
export default {
  name: "StatusBtn",
  props: ["label", "enLabel", "statusId", "student", "attendanceDateObj"],
  computed: {
    color() {
      const statuses = this.getStatused();
      if (!statuses) {
        return null;
      } else if (!statuses.status) {
        return null;
      } else if (statuses.status == 1) {
        if (this.statusId === "attended") {
          return "green";
        } else if (this.statusId === "late") {
          if (statuses.late) {
            return "orange";
          } else {
            return null;
          }
        } else if (this.statusId === "left") {
          if (statuses.left) {
            return "orange";
          } else {
            return null;
          }
        } else if (this.statusId === "absence") {
          return null;
        } else {
          return null;
        }
      } else if (statuses.status == 2) {
        if (this.statusId === "attended") {
          return null;
        } else if (this.statusId === "late") {
          return null;
        } else if (this.statusId === "left") {
          return null;
        } else if (this.statusId === "absence") {
          return "red";
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    isDisable() {
      if (this.attendanceDateObj && this.attendanceDateObj.canceled) {
        return true;
      }
      const statuses = this.getStatused();
      if (!statuses) {
        return false;
      } else if ((statuses.fixed || statuses.sent) && this.color === null) {
        return true;
      } else {
        return false;
      }
    }
  },
  methods: {
    getStatused() {
      if (!this.attendanceDateObj) {
        return null;
      }
      const attendanceStatuses = this.attendanceDateObj.attendanceStatus.filter(
        s => {
          return s.studentId == this.student.uid;
        }
      );
      if (attendanceStatuses.length > 0) {
        const attendanceStatus = attendanceStatuses[0];
        const fixed = attendanceStatus.fixed;
        const sent = attendanceStatus.sent;
        const status = attendanceStatus.status;
        const late = attendanceStatus.late;
        const left = attendanceStatus.left;
        return { fixed, sent, status, late, left };
      } else {
        return null;
      }
    },
    statusCheck() {
      const statuses = this.getStatused();
      if (!(statuses?.fixed || statuses?.sent)) {
        this.$emit("check", this.statusId, this.student, this.getStatused());
      }
    }
  }
};
</script>
<style scoped></style>
