<template>
  <v-row>
    <v-col cols="12">
      <v-select
        :value="type"
        @input="sendType"
        :items="types"
        item-text="label"
        item-value="id"
        label="授業タイプ"
        outlined
      ></v-select>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "TypeSelect",
  props: ["type"],
  components: {},
  data: () => ({
    // type: "normal",
    types: [
      { id: "normal", label: "通常" },
      { id: "addition", label: "補講" }
    ]
  }),
  methods: {
    sendType(t) {
      // this.type = t;
      this.$emit("type", t);
    }
  },
  created() {
    this.$emit("type", this.type);
  }
};
</script>
