<template>
  <v-row>
    <v-col cols="12">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        :return-value.sync="date"
        transition="scale-transition"
        offset-y
        min-width="290px"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :value="viewDate"
            @input="sendDate"
            label="授業日"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker :value="viewDate" @input="sendDate" no-title scrollable>
          <v-spacer></v-spacer>
          <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
          <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>
<script>
export default {
  name: "Date",
  props: ["defaultDate"],
  components: {},
  data: () => ({
    date: null,
    menu: false
  }),
  computed: {
    viewDate() {
      let result;
      if (this.date) {
        result = this.date;
      } else if (this.defaultDate) {
        const d = this.defaultDate;
        const [YY, MM, DD] = [d.getFullYear(), d.getMonth(), d.getDate()];
        result = `${YY}-${String(MM + 1).padStart(2, "0")}-${String(
          DD
        ).padStart(2, "0")}`;
      } else {
        result = new Date().toISOString().substr(0, 10);
      }
      return result;
    }
  },
  methods: {
    sendDate(d) {
      // console.log(d);
      this.date = d;
      this.$emit("date", d);
    }
  },
  created() {
    this.$emit("date", this.viewDate);
  }
};
</script>
