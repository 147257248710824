<template>
  <v-container v-if="getClassroom(courseCode)">
    <v-row>
      <v-col cols="12" md="12">
        <h1>出欠詳細を表示中</h1>
      </v-col>
      <v-col
        cols="12"
        xm="12"
        sm="6"
        md="6"
        v-for="c in getClassrooms(courseCode)"
        :key="c.courseCode"
        ><Classroom
          :numberOf="1"
          :classrooms="[c]"
          view="register"
          :inActive="false"
        />
      </v-col>
      <v-col cols="12" xm="12" sm="6" md="6">
        <v-card class="mx-auto" elevation="8" :height="cardHeight()">
          <v-list-item three-line class="my-0 py-0">
            <v-list-item-content class="md-0">
              <v-list-item-title class="headline mb-1">
                <p v-if="student">{{ student.getName }}</p>
              </v-list-item-title>
              <v-list-item-subtitle>
                <span v-if="student">{{ student.email }}</span>
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
          <v-card-actions class="ma-4 pa-2">
            <v-btn
              v-if="isTeacher"
              :to="`/attendance/${courseCode}`"
              color="primary"
              >戻る(Return)</v-btn
            >
          </v-card-actions>
          <v-card-actions class="ma-4 pa-2" v-if="isTeacher">
            <v-spacer></v-spacer>
            <v-switch
              v-model="switchAttendanceStudent"
              :label="`出欠対象から除外`"
            ></v-switch>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <v-simple-table>
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">
              日付(Date)
            </th>
            <th class="text-center">
              通常/補講(Normal/Addition)
            </th>
            <th class="text-center">
              曜日(Day of the week)
            </th>
            <th class="text-center">
              出欠状況(Status)
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(day, i) in days" :key="i">
            <td class="text-center">
              {{ day.date.getMonth() + 1 }}/{{ day.date.getDate() }}
            </td>
            <td class="text-center">
              {{ getAdditionJa(day) }}
            </td>
            <td class="text-center">
              <v-btn
                class="mx-0 weekdayText"
                depressed
                rounded
                x-small
                :color="getWeekdayColor(day.date.getDay())"
                >{{ getWeekdayName(day.date.getDay()) }}
              </v-btn>
            </td>
            <td class="text-center">
              <Status :status="getStatus(day, student)" />
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-container>
</template>
<script>
import Status from "./Status";
import Classroom from "../Classroom";

export default {
  name: "Table",
  props: ["courseCode", "student"],
  components: {
    Status,
    Classroom
  },
  data: () => ({
    dialog: false,
    weekdays: [
      { id: 1, name: "月曜(Mo)" },
      { id: 2, name: "火曜(Tu)" },
      { id: 3, name: "水曜(We)" },
      { id: 4, name: "木曜(Th)" },
      { id: 5, name: "金曜(Fr)" },
      { id: 6, name: "土曜(Sa)" },
      { id: 0, name: "日曜(Su)" }
      //   { id: 0, name: "その他" }
    ]
  }),
  computed: {
    days() {
      return this.$store.state.attendanceDates.slice().sort((a, b) => {
        return a.date - b.date;
      });
    },
    isTeacher() {
      const userType = this.$store.state.userType;
      if (userType == "coordinator" || userType == "teacher") {
        return true;
      } else {
        return false;
      }
    },
    switchAttendanceStudent: {
      get() {
        return !this.student?.active;
      },
      set(v) {
        const data = {
          courseCode: this.courseCode,
          studentId: this.student.uid,
          active: !v
        };
        this.$store.dispatch("doUnEnrollByTeacher", data);
      }
    }
  },
  methods: {
    cardHeight() {
      return this.$store.state.cardHeight;
    },
    getDateDetailPath(day) {
      const attendanceId = day.attendanceId;
      return `/attendance/${this.courseCode}/date/${attendanceId}`;
    },
    getAdditionJa(day) {
      return day.addition ? "補講(A)" : "通常(N)";
    },
    getStudentPath(studentId) {
      return `/attendance/${this.courseCode}/student/${studentId}`;
    },
    getWeekdayColor(weekdayId) {
      if (weekdayId == 1) {
        return "#779438";
      } else if (weekdayId == 2) {
        return "#007d7a";
      } else if (weekdayId == 3) {
        return "#055a8e";
      } else if (weekdayId == 4) {
        return "#772f6d";
      } else if (weekdayId == 5) {
        return "#a7374a";
      } else {
        return "#000";
      }
    },
    getClassrooms(courseCode) {
      const results = this.$store.state.classrooms.filter(c => {
        return c.courseCode == courseCode || c.parentCourseCode == courseCode;
      });
      return results;
    },
    getClassroom(courseCode) {
      const result = this.$store.state.classrooms.filter(
        c => c.courseCode == courseCode
      );
      if (result.length > 0) {
        return result[0];
      } else {
        return null;
      }
    },
    getWeekdayName(weekdayId) {
      const week = this.weekdays.filter(w => {
        return w.id == weekdayId;
      });
      if (week.length > 0) {
        return week[0].name;
      }
      return "";
    },
    getStatus(day, student) {
      if (!student) {
        return null;
      }
      const studentId = student.uid;
      const statusIndex = day.attendanceStatus.findIndex(a => {
        return a.studentId === studentId;
      });
      if (day.canceled) {
        return "canceled";
      } else if (statusIndex > -1) {
        const StatusObj = day.attendanceStatus[statusIndex];
        const status = StatusObj.status;
        const late = StatusObj.late;
        const left = StatusObj.left;
        if (status === 1) {
          if (late && left) {
            return "late-left";
          } else if (late) {
            return "late";
          } else if (left) {
            return "left";
          } else {
            return "attended";
          }
        } else if (status === 2) {
          return "absence";
        } else if (status === null) {
          return "undefined";
        } else {
          return "alert";
        }
      }
      if (student.active) {
        return "undefined";
      } else {
        return null;
      }
    }
  },
  created() {}
};
</script>
<style scoped>
.weekdayText {
  color: #fff;
}
</style>
