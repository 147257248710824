<template>
  <v-container>
    <v-row>
      <v-col cols="12" md="12">
        <Information />
      </v-col>
    </v-row>
    <v-row v-if="!courseCode">
      以下から授業を選択してください。授業を選択すると、出欠確認が表示されます。<br />
      授業が表示されない場合は、履修科目が登録されていないか、担当する授業が登録されていません。
      <v-col cols="12" md="12">
        <EnrolledList />
      </v-col>
    </v-row>
    <v-container v-if="!hasMultiCourses">
      <v-row v-if="viewTable == 'Table'">
        <v-col cols="12" md="12">
          <Table :courseCode="courseCode" />
        </v-col>
      </v-row>
      <v-row v-if="viewTable == 'TableDetail'">
        <v-col cols="12" md="12">
          <TableDetail :courseCode="courseCode" :attendanceDateObj="day" />
        </v-col>
      </v-row>
      <v-row v-if="viewTable == 'TableStudent'">
        <v-col cols="12" md="12">
          <TableStudent :courseCode="courseCode" :student="student" />
        </v-col>
      </v-row>

      <v-row v-if="courseCode && !viewTable">
        <v-col cols="12" md="12">
          この授業の出欠確認ができません。
        </v-col>
      </v-row>
    </v-container>
    <v-container v-if="hasMultiCourses">
      <v-row>
        <v-col cols="12" md="12">
          <h3>出欠時限選択</h3>
        </v-col>
        <v-col
          cols="12"
          xm="12"
          sm="6"
          md="6"
          v-for="c in getClassrooms(courseCode)"
          :key="c.courseCode"
          ><Classroom
            :numberOf="1"
            :classrooms="[c]"
            view="register"
            :inActive="false"
          />
        </v-col>
        <v-col cols="12" xm="12" sm="6" md="6">
          <v-card
            class="mx-auto mb-2"
            elevation="8"
            height="140"
            v-for="day in sortedMultiCourses"
            :key="day.period"
          >
            <v-list-item three-line class="my-0 py-0">
              <v-list-item-content class="md-0">
                <v-list-item-title class="headline mb-1">
                  <p v-if="day">
                    {{ day.date.getMonth() + 1 }}月{{
                      day.date.getDate()
                    }}日（{{ day.period }}限）
                    {{ getAdditionJa(day) }}
                  </p>
                </v-list-item-title>
                <v-list-item-subtitle v-if="day && day.memo">
                  メモ(Memo): {{ day.memo }}
                </v-list-item-subtitle>
                <v-btn @click="selectedDetail(day)">時限選択</v-btn>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>
<script>
import Information from "../components/Attendance/Information.vue";
import EnrolledList from "../components/Attendance/EnrolledList.vue";
import Table from "../components/Attendance/Table.vue";
import TableDetail from "../components/Attendance/TableDetail";
import TableStudent from "../components/Attendance/TableStudent";
import Classroom from "../components/Classroom";

export default {
  name: "Attendance",
  props: ["attendanceToday"],
  components: {
    Information,
    EnrolledList,
    Table,
    TableDetail,
    TableStudent,
    Classroom
  },
  data: () => ({
    hasMultiCourses: false,
    multiCourses: []
  }),
  computed: {
    courseCode() {
      return this.$route.params.courseCode;
    },
    attendanceId() {
      return this.$route.params.attendanceId;
    },
    studentId() {
      return this.$route.params.studentId;
    },
    day() {
      if (this.attendanceId) {
        const attendanceDateObj = this.$store.state.attendanceDates.filter(
          a => {
            return a.attendanceId == this.attendanceId;
          }
        );
        if (attendanceDateObj.length > 0) {
          return attendanceDateObj[0];
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    student() {
      if (this.studentId) {
        const studentsAttendanceObj = this.$store.state.studentsAttendance.filter(
          s => {
            return s.uid == this.studentId;
          }
        );
        if (studentsAttendanceObj.length > 0) {
          return studentsAttendanceObj[0];
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    courseAttendance() {
      return this.$store.state.courseAttendance;
    },
    studentsAttendance() {
      return this.$store.state.studentsAttendance;
    },
    viewTable() {
      if (this.attendanceToday) {
        this.redirectToday();
        return null;
      }
      if (this.courseCode && this.hasCourseViewPermission) {
        if (this.attendanceId) {
          return "TableDetail";
        } else if (this.studentId) {
          return "TableStudent";
          // } else if (this.attendanceToday) {
          //   this.redirectToday();
          //   return "Table";
        } else {
          return "Table";
        }
      } else if (this.courseCode && this.studentId && this.hasEnrolled) {
        return "TableStudent";
      } else {
        return null;
      }
    },
    hasCourseViewPermission() {
      const userType = this.$store.state.userType;
      if (userType === "coordinator") {
        return true;
      }
      const teachers = this.$store.state.teachCourseList;
      if (teachers) {
        const hasTeacher = teachers.filter(c => {
          return c.courseCode == this.courseCode;
        });
        if (hasTeacher.length > 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    hasEnrolled() {
      if (this.courseCode && this.studentId) {
        const loginUserUid = this.$store.state.loginUser.uid;
        if (this.student) {
          return this.student.uid == loginUserUid;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    sortedMultiCourses() {
      return this.multiCourses.slice().sort((a, b) => {
        return a.sortedDate - b.sortedDate;
      });
    }
  },
  watch: {
    courseCode() {
      this.getDetalData();
    },
    studentId() {
      const userType = this.$store.state.userType;
      if (!(userType == "coordinator" || userType == "teacher")) {
        this.getDetalData();
      }
    },
    hasCourseViewPermission() {
      this.getDetalData();
    }
  },
  methods: {
    async redirectToday() {
      const courseCode = this.courseCode;
      if (courseCode) {
        const userType = this.$store.state.userType;
        const _sleep = ms => new Promise(resolve => setTimeout(resolve, ms));
        await _sleep(500);
        if (userType == "coordinator" || userType == "teacher") {
          const today = new Date();
          const attendanceDate = this.$store.state.attendanceDates.filter(a => {
            return (
              a.date.getFullYear() == today.getFullYear() &&
              a.date.getMonth() == today.getMonth() &&
              a.date.getDate() == today.getDate()
            );
          });
          if (attendanceDate.length > 1) {
            this.hasMultiCourses = true;
            this.multiCourses = attendanceDate;
          } else if (attendanceDate.length == 1) {
            const attendanceId = attendanceDate[0].attendanceId;
            this.$router.push({
              name: "AttendanceDetail",
              params: {
                courseCode: courseCode,
                attendanceId: attendanceId
              }
            });
          } else {
            this.$router
              .push({
                name: "AttendanceWithCode",
                params: {
                  courseCode: courseCode
                }
              })
              .catch(e => {
                console.log("no need AttendanceWithCode redirect: ", e); // もみ消しで良いと思われる
              });
          }
        } else {
          const loginUserUid = this.$store.state.loginUser.uid;
          this.$router
            .push({
              name: "AttendanceStudent",
              params: {
                courseCode: this.courseCode,
                studentId: loginUserUid
              }
            })
            .catch(e => {
              console.log("no need AttendanceStudent redirect: ", e); // もみ消しで良いと思われる
            });
        }
      }
    },
    getDetalData() {
      const courseCode = this.courseCode;
      let studentId = this.studentId;
      this.$store.dispatch("removeCourseAttendance");
      if (courseCode) {
        this.$store.dispatch("getCourseAttendance", courseCode);
        this.$store.dispatch("getCourseAttendanceEnrolled", courseCode);
        if (this.hasCourseViewPermission) {
          studentId = null; // 講師の場合は全員分取得するため
          const data = { courseCode, studentId };
          this.$store.dispatch("getCourseAttendanceDetail", data);
        } else if (studentId) {
          // 学生で見た時に呼び出される
          const data = { courseCode, studentId };
          this.$store.dispatch("getCourseAttendanceDetail", data);
        }
      }
    },
    getClassrooms(courseCode) {
      const results = this.$store.state.classrooms.filter(c => {
        return c.courseCode == courseCode || c.parentCourseCode == courseCode;
      });
      return results;
    },
    getAdditionJa(day) {
      return day.addition ? "補講(A)" : "通常(N)";
    },
    selectedDetail(day) {
      this.hasMultiCourses = false;
      this.multiCourses = [];
      const attendanceId = day.attendanceId;
      this.$router.push({
        name: "AttendanceDetail",
        params: {
          courseCode: this.courseCode,
          attendanceId: attendanceId
        }
      });
    }
  },
  mounted() {},
  created() {
    // const courseCode = this.courseCode;
    this.getDetalData();
  }
};
</script>
