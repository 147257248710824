<template>
  <v-container>
    <v-form ref="forms">
      <h2 class="headline mb-1">
        {{ classroom.name }}
      </h2>
      <div>
        <v-chip class="ma-2" color="accent">
          {{ classroom.courseCode }}
        </v-chip>
        <v-chip class="ma-2" color="accent"> {{ classroom.className }} </v-chip>
      </div>
      <v-row>
        <v-col>
          <v-card ref="form">
            <v-card-text>
              <Date @date="setDate" :defaultDate="getDate" />
              <TypeSelect @type="setType" :type="getType" />
              <PeriodSelect @period="setPeriod" :period="getPeriod" />
              <v-text-field
                :value="getMemo"
                @input="setMemo"
                label="メモ"
                placeholder="授業についてのメモがあれば記入"
              ></v-text-field>
            </v-card-text>
            <v-card-actions>
              <v-btn
                class="ma-2"
                color="secondary"
                @click="submit"
                :disabled="!canSubmit"
                >{{ submitName }}</v-btn
              ></v-card-actions
            >
          </v-card>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import Date from "./Date";
import TypeSelect from "./TypeSelect";
import PeriodSelect from "./PeriodSelect.vue";

export default {
  name: "AddDateForm",
  props: [
    "classroom",
    "mode",
    "defaultMemo",
    "defaultDate",
    "defaultType",
    "defaultPeriod",
    "attendanceId"
  ],
  components: { Date, TypeSelect, PeriodSelect },
  data: () => ({
    memo: null,
    date: null,
    type: null,
    period: null
  }),
  computed: {
    canSubmit() {
      const date = this.date;
      const type = this.type;
      const period = this.period;
      if (date && type && period) {
        return true;
      } else {
        return false;
      }
    },
    submitName() {
      if (this.mode === "add") {
        return "作成";
      } else if (this.mode === "edit") {
        return "更新";
      } else {
        return "";
      }
    },
    getMemo() {
      if (this.mode === "edit") {
        return this.defaultMemo;
      } else {
        return null;
      }
    },
    getDate() {
      if (this.mode === "edit") {
        return this.defaultDate;
      } else {
        return null;
      }
    },
    getType() {
      if (this.mode === "edit") {
        return this.defaultType;
      } else {
        return null;
      }
    },
    getPeriod() {
      if (this.mode === "edit") {
        return this.defaultPeriod;
      } else {
        return null;
      }
    }
  },
  methods: {
    setDate(date) {
      this.date = date;
    },
    setType(type) {
      this.type = type;
    },
    setPeriod(period) {
      this.period = period;
    },
    setMemo(memo) {
      this.memo = memo;
    },
    submit() {
      const period = this.period;
      if (period) {
        const data = {
          classroom: this.classroom,
          date: this.date,
          type: this.type,
          period: period,
          memo: this.memo
        };
        if (this.mode === "add") {
          this.$store.dispatch("makeAttendanceDate", data);
        } else if (this.mode === "edit") {
          const attendanceId = this.attendanceId;
          this.$store.dispatch("modifyAttendanceDate", {
            attendanceId,
            ...data
          });
        }
        this.$emit("dialog", false);
        this.memo = null;
        this.date = null;
        this.type = null;
        this.period = null;
      }
    }
  }
};
</script>
