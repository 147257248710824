<template>
  <v-container pa-0>
    <v-tooltip v-model="show" top v-if="status == 'attended'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="green">mdi-checkbox-marked</v-icon>
        </v-btn>
      </template>
      <span>出席(Attendance)</span>
    </v-tooltip>

    <v-tooltip v-model="show" top v-if="status == 'late'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="orange">mdi-account-arrow-left</v-icon>
        </v-btn>
      </template>
      <span>遅刻(Late)</span>
    </v-tooltip>

    <v-tooltip v-model="show" top v-if="status == 'left'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="orange">mdi-account-arrow-right-outline</v-icon>
        </v-btn>
      </template>
      <span>早退(Early)</span>
    </v-tooltip>

    <v-tooltip v-model="show" top v-if="status == 'others'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="orange">mdi-account-alert</v-icon>
        </v-btn>
      </template>
      <span>出席その他(Others)</span>
    </v-tooltip>

    <v-tooltip v-model="show" top v-if="status == 'late-left'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="orange">mdi-account-convert-outline</v-icon>
        </v-btn>
      </template>
      <span>遅刻早退(Late/Early)</span>
    </v-tooltip>

    <v-tooltip v-model="show" top v-if="status == 'absence'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="red">mdi-minus-circle</v-icon>
        </v-btn>
      </template>
      <span>欠席(Absence)</span>
    </v-tooltip>

    <v-tooltip v-model="show" top v-if="status == 'alert'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon color="orange">mdi-alert</v-icon>
        </v-btn>
      </template>
      <span>注意(Caution)</span>
    </v-tooltip>

    <v-tooltip v-model="show" top v-if="status == 'undefined'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-dots-horizontal</v-icon>
        </v-btn>
      </template>
      <span>未定(Undefined)</span>
    </v-tooltip>
    <v-tooltip v-model="show" top v-if="status == 'canceled'">
      <template v-slot:activator="{ on, attrs }">
        <v-btn icon v-bind="attrs" v-on="on">
          <v-icon>mdi-pencil-off</v-icon>
        </v-btn>
      </template>
      <span>休講(Canceled)</span>
    </v-tooltip>
  </v-container>
</template>
<script>
export default {
  name: "Status",
  props: ["status"],
  data() {
    return {
      show: false
    };
  }
};
</script>
